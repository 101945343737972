import { GlobalStyle } from "@/styles/global";
import { TABS_ITEMS } from "@/utils/common";
import { useEffect } from "react";
import { Foot, Footer } from "./style";
import { history } from "umi";
import { FlatButton } from "@/components/flat-button";
const BaseFooter: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <GlobalStyle />
      <FlatButton />
      <Footer>
        <Foot>
          <div className="wrap">
            {TABS_ITEMS.map((el) => {
              return (
                <div className="info" key={el.key}>
                  <div className="label">{el.label}</div>
                  {el.item.map((item: any) => {
                    return (
                      <div
                        key={item.key}
                        className="title"
                        onClick={() => {
                          item && item.path && history.push(item.path);
                        }}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div>
              <div className="common-tilte">
                <div className="label">友情链接</div>
              </div>
              <div className="wrap wrap-item">
                <div className="item">
                  <a
                    className="title"
                    href="https://www.miit.gov.cn/jgsj/xfpgys/index.html"
                  >
                    工业和信息化部消费品工业司
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.cnga.org.cn">
                    中国服装协会
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.ctei.cn">
                    中国纺织工业联合会
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.fashion.org.cn">
                    中国服装设计师协会
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.ccta.org.cn">
                    中国棉纺织行业协会
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.fashioncolor.org.cn">
                    中国流行色协会
                  </a>
                </div>
                <div className="item">
                  <a className="title" href="http://www.csma.org.cn">
                    中国缝纫机械协会
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="base-title">
              <div>橙织</div>
              <div> 服装工业互联网平台</div>
            </div>
            {/* <div style={{ fontSize: "12px", marginBottom: "26px" }}>
              服装产业数字化转型领导者
            </div> */}
            <div className="base-title contanct">联系我们</div>
            <div>请致电：4006-621-521</div>
            <div style={{ marginTop: "6px" }}>
              浙江省杭州市临平区南苑街道迎宾路551-1号
            </div>
            <div className="base-title follow"> 关注我们</div>
            <div>
              <img
                src={require("@/assets/images/scale.png")}
                alt=""
                style={{ width: "116px", height: "116px" }}
              />
            </div>
          </div>
        </Foot>
        <a
          style={{ marginTop: "40px", textAlign: "center", color: " #fff" }}
          href="https://beian.miit.gov.cn/#/Integrated/index"
        >
          &copy;中国服装科创研究院 {new Date().getFullYear()} ALL RIGHTS
          RESERVED. JOSEPH 浙ICP备2020038903号-2
        </a>
      </Footer>
    </div>
  );
};

export default BaseFooter;
